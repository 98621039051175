import { useEffect, useState } from "react";
import "./selectedLiveFull.scss";
import { useNavigate, useParams } from "react-router-dom";
import { RiFullscreenLine } from "react-icons/ri";
import { MdDarkMode, MdLightMode } from "react-icons/md";
import { calculatePercantage, flagToColor, getPositionArrow, getRaceTime } from "../../../../helpers/Functions";
import QRCode from 'qrcode.react';
import { CiClock2 } from "react-icons/ci";

interface raceDataElement {
    race_details: any;
    race_competitors_list: any;
    race_time: any;
};

const legendDataRace: Object[] = [
    {name: "Status", width: "3%"},
    {name: "Position / Pos. in Class", width: "6%"},
    {name: "No.", width: "3%"},
    {name: "Competitor", width: "23%"},
    {name: "Nationality", width: "5%"},
    {name: "Class", width: "10%"},
    {name: "Team", width: "15%"},
    {name: "Total Time", width: "7%"},
    {name: "Difference", width: "7%"},
    {name: "Gap", width: "7%"},
    {name: "Laps", width: "5%"},
    {name: "Last Time", width: "8.35%"},
];

const legendDataQualification: Object[] = [
    {name: "Status", width: "3%"},
    {name: "Position / Pos. in Class", width: "6%"},
    {name: "No.", width: "3%"},
    {name: "Competitor", width: "23%"},
    {name: "Nationality", width: "5%"},
    {name: "Class", width: "10%"},
    {name: "Team", width: "15%"},
    {name: "Best Time", width: "7%"},
    {name: "In Lap", width: "7%"},
    {name: "Difference", width: "7%"},
    {name: "Gap", width: "7%"},
    {name: "Last Time", width: "7%"}
];

// For Race Winner line
interface WinnerProps {
    winner: any;
    flag: string;
    race_type_id: number;
}

const Winner: React.FC<WinnerProps> = ({ winner, flag, race_type_id }) => {

    if (!winner || !["Finish", "None"].includes(flag) || race_type_id === 3) return null;
    // Dont show if its qualification mode and everyone is not finished
    if (race_type_id === 1 && flag !== "None") return null; // Qualification

    const { nr, state, state2, competitor, class: winnerClass } = winner;
    const title = race_type_id === 2 ? "Race Winner" : "Qualification Winner";

    return (
        <div className="app__live-winner-full">
            <p>{title}</p>
            <div className="app__live-winner-info">
                <div className="app__live-winner-nr">{nr}</div>
                {state && (
                    <div className="app__live-winner-state">
                        <img src={`https://flagcdn.com/${state.toLowerCase()}.svg`} alt={""}/>
                    </div>
                )}
                {state2 && (
                    <div className="app__live-winner-state">
                        <img src={`https://flagcdn.com/${state2.toLowerCase()}.svg`} alt={""}/>
                    </div>
                )}
                <span>{competitor}</span>
                <p>{winnerClass}</p>
            </div>
        </div>
    );
};

// For Fullscreen header
interface HeaderProps {
    activeRace: any;
    hash: string;
    setMode: (value: any) => void;
    mode: string;
}

const Header: React.FC<HeaderProps> = ({ activeRace, hash, setMode, mode }) => {
    const navigate = useNavigate();
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const formatTime = (date: Date) => {
        return date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    };


    if (!activeRace) {
        return null;
    }

    const { name, track_name, track_length, flag, race_type, championship } = activeRace?.race_details || {};

    return (
        <header style={{color: mode === "light" ? "#131313" : "azure"}}>
            <div className="app_liveList-fullscreen-track-name">
                <p>{name}</p>
                <span>{championship}</span>
                <span>{track_name}</span>
                <span>{`Length: ${track_length} m`}</span>
            </div>
            <div className="app_liveList-fullscreen-local-time">
                <CiClock2 size={"25px"}/>
                <p>{formatTime(currentTime)}</p>
            </div>
            <div className="app_liveList-fullscreen-time">
                <p>{getRaceTime(activeRace)}</p>
            </div>
            <div
                className="app_liveList-fullscreen-flag-line"
                style={{background: flagToColor(flag)}}
            />
            <div className="app_liveList-qr">
                <QRCode value={`https://raceonline.eu/live/${hash}`} />
            </div>
            <p className="app_liveList-qr-message">Scan this QR code to view live results on your device.</p>
            <button className="app_liveList-mode-toggle" onClick={() => { setMode((prev: string) => prev === "light" ? "dark" : "light")}}>
                { mode === "dark" ? <MdDarkMode size={"20px"} color="white"/> : <MdLightMode size={"20px"} color="yellow"/> }
            </button>
            <button className="app_liveList-fullscreen-toggle"
                onClick={() => { navigate(`/live/${hash}`) }}
            >
                <RiFullscreenLine color="#ffffff" size={"20px"}/>
            </button>
            <div className="app_liveList-fullscreen-type">
                {race_type}
            </div>
        </header>
    );
};

// Legend
interface LegendProps {
    darkMode: string;
    multipleClasses: boolean;
    raceTypeId: number;
    legendDataRace: any[];
    legendDataQualification: any[];
}

const Legend: React.FC<LegendProps> = ({ darkMode, multipleClasses, raceTypeId, legendDataRace, legendDataQualification }) => {
    const legendData = raceTypeId === 2 ? legendDataRace : legendDataQualification;

    return (
        <div className="app_liveList-fullscreen-legend">
            {legendData.map((item: any, index: number) => (
                <div
                    className="app_liveList-fullscreen-legend-item"
                    style={{
                        width: item.width,
                        background: darkMode === "light" ? "#f2f2f2" : "#787f99",
                        color: darkMode === "light" ? "#000" : "#fff"
                    }}
                    key={`race_live_item_legend_${index}`}
                >
                    {
                        item.name === "Position / Pos. in Class" ?
                        multipleClasses ?
                        <div className="pos_pos_in_class">
                            <p>Position /</p>
                            <p>Pos. in Class</p>
                        </div>
                        :
                        "Position"
                        :
                        item.name
                    }
                </div>
            ))}
        </div>
    );
};

const classNameFromStatus = (item: any, index: number, mode: string, flag: string) => {
    let className = "";
    if (["+", "-", "="].includes(item?.flash_status) && flag !== "Warmup") {
        switch (item?.flash_status) {
            case "+": className = "flash_resizable_item_green"; break;
            case "-": className = "flash_resizable_item_red"; break;
            case "=": className = "flash_resizable_item_custom-flash"; break;
        }
    } else if (item?.status === "F") {
        className = "flash_resizable_item_finish";
    } else {
        className = index % 2 === 0 ? "flash_resizable_item_custom-prim" : "flash_resizable_item_custom-second";
    }
    return mode === "dark" ? className : `${className}_light`;
}


export const SelectedLiveFull = (props: any) => {

    const {hash} = useParams();
    const [activeRace, setActiveRace] = useState<raceDataElement>();
    const [currentPage, setCurrentPage] = useState(1);
    const [mode, setMode] = useState<"light" | "dark">("dark");

    const showResultsByClass = activeRace?.race_details?.availableClasses?.length > 1;

    useEffect(() => {
        props.ws.send(JSON.stringify({ type: "join_room", room_id: hash }));
        return () => {
            props.ws.send(JSON.stringify({ type: "leave_room", room_id: hash }));
        };
    }, [hash]);

    useEffect(() => {
        const handleMessage = (event: any) => {
            const message = JSON.parse(event.data);
            switch (message.type) {
                case "race_event":

                    if (!message.data) return;
                    const { race_details, race_competitors_list, race_time } = message.data;

                    race_competitors_list?.forEach((competitor: any) => {
                        competitor.percentage = calculatePercantage(competitor, race_details?.track_length,
                            race_time?.elapsed_time
                        );
                        competitor.best_in_lap = competitor.best_in_lap === 0 || competitor.best_in_lap === "0" ? "" : competitor.best_in_lap
                        competitor.lap_time = competitor.lap === 0 || competitor.lap === "0" || competitor.lap === "" ? "" : competitor.lap_time
                    });

                    if (race_competitors_list?.length > 20) {
                        const maxPages = Math.ceil(race_competitors_list.length / 20);

                        setActiveRace({
                            race_details,
                            race_competitors_list: race_competitors_list.slice((currentPage - 1) * 20, currentPage * 20),
                            race_time
                        });

                        setTimeout(() => {
                            setCurrentPage(currentPage < maxPages ? currentPage + 1 : 1);
                        }, 10000);
                    } else {
                        setActiveRace(message.data);
                    }

                    break;
                default:
                    console.log(`Unknown message type: ${message.type}`);
            }
        };

        props.ws.addEventListener('message', handleMessage);
        return () => {
            props.ws.removeEventListener('message', handleMessage);
        }
    }, [props.ws, currentPage]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        const mouseMoved = () => {
            document.body.classList.remove('cursor-hidden');
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => document.body.classList.add('cursor-hidden'), 5000);
        };

        window.addEventListener('mousemove', mouseMoved);
        document.body.classList.remove('cursor-hidden');

        return () => {
            window.removeEventListener('mousemove', mouseMoved);
            document.body.classList.remove('cursor-hidden');
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <div
            className="app_liveList-fullscreen"
            style={{background: mode === "dark" ? "#232531" : "white"}}
        >
            {
                activeRace &&
                <Header
                    activeRace={activeRace}
                    hash={hash || ""}
                    setMode={setMode}
                    mode={mode}
                />
            }
            {
                <Winner
                    winner={activeRace?.race_details?.winner}
                    flag={activeRace?.race_details?.flag}
                    race_type_id={activeRace?.race_details?.race_type_id}
                />
            }
            {
                activeRace ?
                <div className="app_liveList-fullscreen-data">
                    <Legend
                        darkMode={mode}
                        multipleClasses={showResultsByClass}
                        raceTypeId={activeRace?.race_details?.race_type_id}
                        legendDataRace={legendDataRace}
                        legendDataQualification={legendDataQualification}
                    />
                    <div
                        className="app_liveList-fullscreen-comp-data"

                    >
                        {
                            activeRace?.race_competitors_list?.map((item: any, index: number) =>
                                <>
                                    <div
                                        className={`app_liveList-fullscreen-item ${classNameFromStatus(item, index, mode, activeRace?.race_details?.flag)}`}
                                        key={`race_live_item_${index}${item?.nr}`}
                                        style={{color: mode === "light" ? "black" : "white"}}
                                    >
                                        <div className="app_liveList-fullscreen-position-c">
                                            {
                                                getPositionArrow(item?.status, 18, mode === "light")
                                            }
                                            <p></p>

                                        </div>
                                        <div className="app_liveList-fullscreen-pos">
                                            <div className="app_liveList-fullscreen-pos-inner">
                                                <p>{item?.position}</p>
                                                {
                                                    showResultsByClass &&
                                                    <div className="app_liveList-fullscreen-pos-inner-lower">
                                                        <p>/</p>
                                                        <span>{item?.position_in_class}</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className={ mode === "light" ? "app_liveList-fullscreen-nr-light" : "app_liveList-fullscreen-nr"}>
                                            {item?.nr}
                                        </div>
                                        <div className="app_liveList-fullscreen-20">
                                            {item?.competitor}
                                        </div>
                                        <div className="app_liveList-fullscreen-5">
                                        {
                                            item.state &&
                                            <img src={`https://flagcdn.com/${item.state?.toLowerCase()}.svg`} alt="-" />
                                        }
                                        {
                                            item?.state2 &&
                                            <img src={`https://flagcdn.com/${item.state2?.toLowerCase()}.svg`} alt="-" />
                                        }
                                        </div>
                                        <div className="app_liveList-fullscreen-10">
                                            <p>{item?.class}</p>
                                        </div>
                                        <div className="app_liveList-fullscreen-10" style={{width: "15%"}}>
                                            <p>{item?.team}</p>
                                        </div>
                                        {
                                            activeRace?.race_details?.race_type_id === 2 ?
                                            <>
                                                <div className="app_liveList-fullscreen-7">
                                                    <p>{item?.elapsed_time}</p>
                                                </div>
                                                <div className="app_liveList-fullscreen-7">
                                                    <p>{item?.diff}</p>
                                                </div>
                                                <div className="app_liveList-fullscreen-7">
                                                    <p>{item?.gap}</p>
                                                </div>
                                                <div className="app_liveList-fullscreen-5">
                                                    <p>{item?.lap}</p>
                                                </div>
                                                <div className="app_liveList-fullscreen-7" style={{ width: "8.35%"}}>
                                                    <p>{item?.lap_time}</p>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="app_liveList-fullscreen-7">
                                                    <p>{item?.best_lap_time}</p>
                                                </div>
                                                <div className="app_liveList-fullscreen-7">
                                                    <p>{item?.best_in_lap}</p>
                                                </div>
                                                <div className="app_liveList-fullscreen-7">
                                                    <p>{item?.diff}</p>
                                                </div>
                                                <div className="app_liveList-fullscreen-7">
                                                    <p>{item?.gap}</p>
                                                </div>
                                                <div className="app_liveList-fullscreen-7" style={{ width: "6.95%"}}>
                                                    <p>{item?.lap_time}</p>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="app_liveList-fullscreen-percentage">
                                        <div
                                            className="app_liveList-fullscreen-percenage-bar"
                                            style={{
                                                    width: `${item?.percentage}%`,
                                                    background: mode === "light" ? "#09244f" : "#2febf5"
                                            }}
                                            key={`race_live_item_percentage_${index}${item?.nr}`}
                                        />
                                    </div>
                                </>

                            )
                        }
                    </div>
                    {/* <div className="app_liveList-fullscreen-footer">
                        <p>View all live results and result history on <span>live.racegraphics.eu</span>.</p>
                    </div> */}
                </div>
                :
                <div className="app_liveList-fullscreen-loading">
                    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    <p>Loading Fullscreen Mode...</p>
                </div>
            }
        </div>
    );
};