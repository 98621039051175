import "./selectedLiveRace.scss";
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";

import ResizableTable from "../../../../components/ResizeTable/ResizableTable";
import { useMediaQuery } from "react-responsive";
import ResizableTableFilter from "../../../../components/ResizeTable/ResizableFilter";
import ScoreboardLeaderRow from "../../../../components/ScoreboardLeaderRow";
import SettingsSelector from "../../../../components/Selectors/SettingsSelector";
import ActiveRaceHeader from "../../../../components/Headers/ActiveRaceHeader";

import { MdEmail } from "react-icons/md";
import { FaFacebookSquare } from "react-icons/fa";
import { FaTwitter, FaWhatsapp } from "react-icons/fa6";

// share
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton } from "react-share";

// Resizable components
import { ResizableTableFastestLapComponent,
         ResizableTableImgComponent,
         ResizableTablePosMobileComponent,
         ResizableTableStartNrComponent,
         ResizableTableStatusComponent,
         ResizableTableSurnameMobileComponent
        } from "../resizableTableComponents/resizableTableComponents";
import { classToColor, calculatePercantage } from "../../../../helpers/Functions";

import Simulator from "./Simulator";
import PacketGraph from "./packetGraph";

interface raceDataElement {
    race_details: any;
    race_competitors_list: any;
    race_time: any;
};

export const calculateColorPosNeg = (data: number) => {
    if (data < 0) return '#eb4b4b';
    if (data > 0) return 'hsl(120, 100%, 50%)';
    return "white";

}

const ResizableTablePercentage = (props: any) => {

    const colors = [
        "#4962ff", "#4a87fa", "#4aa2f7", "#4abef3", "#4ad9ef",
        "#4dd9d2", // Existing colors
        "#4de4ce", "#4deec9", "#4ff8c4", "#51ffbf" // New colors added
    ];

    return (
        <div className="resized_percentage">
            {
                Array.from({ length: 10}).map((_, index: number) => {
                    // Determine the percentage each box represents
                    const boxPercentage = (index + 1) * 10;
                    const dataPercentage = parseInt(props.data);
                    let backgroundColor = "#090d13";
                    let isFlashing = false;

                    if (dataPercentage >= boxPercentage) {
                        backgroundColor = colors[index];
                    }  else if (dataPercentage > index * 10 && dataPercentage < boxPercentage) {
                        // Calculate the width of the partially filled box
                        const partialFillPercentage = ((dataPercentage % 10) * 10).toFixed(0);
                        backgroundColor = `linear-gradient(90deg, ${colors[index]} ${partialFillPercentage}%, #0c0c11 ${partialFillPercentage}%)`; // Replace 'specificColor' with your desired color
                        isFlashing = true;
                    }

                    return (
                        <div
                        className={`resized_percentage_box ${isFlashing ? 'flashing' : ''}`}
                            key={`resized_box_${props?.index}${index}`}
                            style={{
                                backgroundColor: backgroundColor.includes('linear-gradient') ? 'transparent' : backgroundColor,
                                backgroundImage: backgroundColor.includes('linear-gradient') ? backgroundColor : 'none',
                            }}
                        >{index === 0 && <p>{props.data}</p>}{index === 9 && <p>%</p>}</div>
                    );
                })
            }
        </div>
    );
}

const ResizableTableFirstnameComponent = (props: any) => {
    if (!props?.data) return null;
    const data = props?.data?.replace("/", " |");
    return (
        <span className="resized_position_firstname">
            <p>{data}</p>
        </span>
    );
};

const ResizableTableMove = (props: any) => {
    const parsedData = parseInt(props.data, 10);
    if (isNaN(parsedData)) return "";
    return <p style={{ color: calculateColorPosNeg(parsedData)}}>{props.data}</p>
}

const ResizableTableChange = (props: any) => {
    const parsedData = parseInt(props.data, 10);
    if (isNaN(parsedData)) return "";
    return <p style={{ color: calculateColorPosNeg(parsedData)}}>{props.data !== "0" ? props.data : ""}</p>
}

const ResizableTablePos = (props: any) => {
    if (["DNS", "DNF", "DQ"].includes(props.data)) {
        return (
            <div className="resized_retired_position">
                {props.data}
            </div>
        );
    }

    return props.byClass ?
    <>
        <p>{props.data}</p>
        <hr/>
        <p>{props.resultsByClassPosAlt}</p>
    </>
    :
    <p>{props.data}</p>
}

const ResizableTableClass = (props: any) => {
    if (!props?.data) return null;

    return (
        <div className="resized_position_class">
            <div
                className="resized_position_class_color"
                style={{backgroundColor: classToColor(props.data, props.race_info.availableClasses)}}
            />
            <p>{props.data}</p>
        </div>
    );
}

const ResizableTableLast5 = (props: any) => {
    // Initialize an array with 5 undefined elements
    const laps = new Array(5).fill(undefined);

    // Fill the first part of the array with props.data if available
    props.data?.forEach((item: any, index: number) => {
        if (index < 5) laps[index] = item;
    });

    return (
        <div className="resized_position_last5">
            {laps.map((lap: any, index: number) => (
                <p
                    key={`resized_last5_${index}`}
                    style={{
                        background: lap?.status === "+" ? "#2dcc30" :
                        lap?.status === "-" ? "#ff3636" : lap?.status === "=" ? "white" : "#1d1c28", // Use transparent for empty slots
                    }}
                />
            ))}
        </div>
    );
}


const fields = {
    banned: ["position_by_time", "position_by_lap", "diff_by_time", "diff_by_lap",
             "gap_by_time", "gap_by_lap", "position_change_by_time", "position_change_by_lap",
             "finished_time", "is_sidecar", "firstname", "lastname", "position_by_class",
             "status", "old_lap", "flash_status", "speed", "lap_position", "best_speed",
             "position_in_class", "isSidecar", "average_speed", "marker", "state2"],
    enabled: ["percentage","position", "nr", "state", "fixed_status", "competitor", "class", "team", "lap", "best_lap_time", "best_in_lap", "gap", "diff", "lap_time", "best_speed"],
    verticalModeDefaults: {
        raceMode: {
            position: {
                customElement: ResizableTablePosMobileComponent,
                width: '13vw',  // percentage
                maxWidth: 60,   // pixels
                shortname: "Pos."
            },
            nr: {
                width: '12vw',
                maxWidth: 60,
                shortname: "No",
                customElement: ResizableTableStartNrComponent
            },
            competitor: {
                width: '50vw',
                justifyStart: true,
                shortname: "Competitor",
                customElement: ResizableTableSurnameMobileComponent
            },
            elapsed_time: {
                width: '25vw',
                shortname: "Total time"
            },
            best_lap_time: {
                width: '33vw',
                shortname: "Best time"
            },
            lap_time: {
                width: '33vw',
                shortname: "Last time"
            },
            lap: {
                width: '16vw',
                shortname: "Laps"
            }
        },
        qualificationMode: {
            position: {
                customElement: ResizableTablePosMobileComponent,
                width: '13vw',  // percentage
                maxWidth: 60,   // pixels
                shortname: "Pos."
            },
            nr: {
                width: '12vw',
                maxWidth: 60,
                shortname: "No",
                customElement: ResizableTableStartNrComponent
            },
            competitor: {
                    width: '50vw',
                    justifyStart: true,
                    shortname: "Competitor",
                    customElement: ResizableTableSurnameMobileComponent
            },
            best_lap_time: {
                width: '25vw',
                shortname: "Best lap"
            },
            diff: {
                width: '25vw',
                shortname: "Difference"
            },
            lap_time: {
                width: '25vw',
                shortname: "Last time"
            },
            lap: {
                width: '16vw',
                shortname: "Laps"
            }
        }
    },
    enabledClasses: [],
    defaultFontSize: 20,
    order: ["percentage", "orig_position", "move", "change", "fixed_status", "position", "nr", "competitor", "state", "state2", "class", "team", "make", "diff", "gap", "lap", "lap_time", "best_lap_time", "best_in_lap", "elapsed_time", "best_speed"],
    config: {
        position: {
            label: "Pos",
            defaultWidth: 60,
            customElement: ResizableTablePos
        },
        nr: {
            customElement: ResizableTableStartNrComponent,
            label: "No.",
            defaultWidth: 70
        },
        state: {
            customElement: ResizableTableImgComponent,
            label: "Nat",
            isImageElement: true,
            defaultWidth: 80
        },
        fixed_status: {
            customElement: ResizableTableStatusComponent,
            label: "Status",
            defaultWidth: 90,
        },
        orig_position: {
            label: "S Pos",
            defaultWidth: 90
        },
        move: {
            label: "Change from start",
            defaultWidth: 90,
            customElement: ResizableTableMove
        },
        change: {
            label: "Change in lap",
            defaultWidth: 90,
            customElement: ResizableTableChange
        },
        percentage: {
            label: "Approx lap completion",
            customElement: ResizableTablePercentage,
        },
        competitor: {
            label: "Name",
            defaultWidth: 330,
            customElement: ResizableTableFirstnameComponent,
        },
        best_lap_time: {
            label: "Best lap",
            defaultWidth: 150,
            customElement: ResizableTableFastestLapComponent,
        },
        lap_time: {
            label: "Last lap",
        },
        last_time_array: {
            label: "Last 5 laps",
            defaultWidth: 120,
            customElement: ResizableTableLast5
        },
        elapsed_time: {
            label: "Total time",
        },
        make: { label: "Make" },
        team: { label: "Team/Club", defaultWidth: 140 },
        lap: { label: "Laps", defaultWidth: 60 },
        diff: { label: "Difference", defaultWidth: 90 },
        gap: { label: "Gap", defaultWidth: 90 },
        class: {
            label: "Class",
            customElement: ResizableTableClass
        },
        best_in_lap: { label: "Best in lap", defaultWidth: 90 },
    }
};

interface ShareButtonsProps {
    resultsByClass: boolean;
};


const ShareButtons: React.FC<ShareButtonsProps> = ({ resultsByClass }) => {
    const url = window.location.href;

    return (
        <div className="app__historyRace-navbar-infobox">
            <div className="app__historyRace-table-route" />
            <div
                className="app__historyRace-table-share"
                style={{
                    borderBottomRightRadius: !resultsByClass ? "10px" : "0px",
                    borderBottomLeftRadius: resultsByClass ? "10px" : "0px"
                }}
            >
                <EmailShareButton url={url}>
                    <MdEmail size={"20px"} color={"#1d9bf0"} />
                </EmailShareButton>
                <WhatsappShareButton url={url}>
                    <FaWhatsapp size={"20px"} color={"#25d366"} />
                </WhatsappShareButton>
                <FacebookShareButton url={url} hashtag={"LiveTiming"}>
                    <FaFacebookSquare size={"20px"} color={"#0866ff"}/>
                </FacebookShareButton>
                <TwitterShareButton url={url} hashtags={["LiveTiming"]}>
                    <FaTwitter className="share-icon-ml" size={"20px"} color={"#1d9bf0"}/>
                </TwitterShareButton>
            </div>
        </div>
    );
};

interface RaceWinnerProps {
    activeRace: any;
    isTabletOrMobile: boolean;
    localStorageEnabledSettings: any;
}

const RaceWinner: React.FC<RaceWinnerProps> = ({ activeRace, isTabletOrMobile, localStorageEnabledSettings }) => {

    const [showText, setShowText] = useState(true);

    useEffect(() => {
        const textInterval = setInterval(() => {
            setShowText(prev => !prev);
        }, 5000);

        return () => {
            clearInterval(textInterval);
        };
    }, []);
    if (!activeRace?.race_details?.winner || !["Finish", "None"].includes(activeRace?.race_details?.flag || activeRace?.race_details?.race_type_id === 3)) {
        return null;
    }
    if (activeRace?.race_details?.race_type_id === 1 && activeRace?.race_details?.flag !== "None") { // Qualification
        return null;
    }

    if (isTabletOrMobile) {
        return (
            <div className="app__live-winner-mobile">
                <div className={`app__live-winner-mobile-element ${showText ? 'slide-in' : 'slide-out'}`}>
                    <p>
                        {
                            activeRace?.race_details?.race_type_id === 2 ?
                            "Race Winner" : "Qualification Winner"
                        }
                    </p>
                </div>
                <div className={`app__live-winner-mobile-element ${!showText ? 'slide-in' : 'slide-out'}`}>
                    <div className="app__live-winner-mobile-info">
                        <div className="app__live-winner-mobile-nr">
                            {activeRace?.race_details?.winner?.nr}
                        </div>
                        {
                            activeRace?.race_details?.winner?.state &&
                            <div className="app__live-winner-mobile-state">
                                <img src={`https://flagcdn.com/${activeRace?.race_details?.winner?.state.toLowerCase()}.svg`} alt={""}/>
                            </div>
                        }
                        {
                            activeRace?.race_details?.winner?.state2 &&
                            <div className="app__live-winner-mobile-state">
                                <img src={`https://flagcdn.com/${ activeRace?.race_details?.winner?.state2.toLowerCase()}.svg`} alt={""}/>
                            </div>
                        }
                        <span>{activeRace?.race_details?.winner?.competitor}</span>
                        <p>{activeRace?.race_details?.winner?.class}</p>
                    </div>
                </div>
            </div>
        );
    }
    if (!localStorageEnabledSettings?.[1]) { return null;}

    if (activeRace?.race_details?.race_type === "Training") return null;
    return (
        <div className="app__live-winner">
            <p>{
                activeRace?.race_details?.race_type_id === 2 ?
                "Race Winner" : "Qualification Winner"
            }
            </p>
            <div className="app__live-winner-info">
                <div className="app__live-winner-nr">
                    {activeRace?.race_details?.winner?.nr}
                </div>
                {
                    activeRace?.race_details?.winner?.state &&
                    <div className="app__live-winner-state">
                        <img src={`https://flagcdn.com/${activeRace?.race_details?.winner?.state.toLowerCase()}.svg`} alt={""}/>
                    </div>
                }
                {
                    activeRace?.race_details?.winner?.state2 &&
                    <div className="app__live-winner-state">
                        <img src={`https://flagcdn.com/${ activeRace?.race_details?.winner?.state2.toLowerCase()}.svg`} alt={""}/>
                    </div>
                }
                <span>{activeRace?.race_details?.winner?.competitor}</span>
            </div>
        </div>
    );
};


const defaultScoreboardColors = ["#2c2f3e", "#232531"];

export const SelectedLiveRace = React.memo((props: any) => {
    const {hash} = useParams();
    const [activeRace, setActiveRace] = useState<raceDataElement>();
    const [currentViewers, setCurrentViewers] = useState<number>(0);
    const [fieldsData, setFieldData] = useState<any>(fields);
    const [resultsByClass, setResultsByClass] = useState<boolean>(false);
    const [forceUpdate, setForceUpdate] = useState<boolean>(false);
    const [localStorageEnabledSettings, setLocalStorageEnabledSettings] = useState<any>(() => {
        const data = localStorage.getItem("checkboxStates");
        const parsed = data ? JSON.parse(data) : {};
        return parsed;
    });

    const ref = useRef(null);

    const [showFilters, setShowFilters] = useState<boolean>(false);

    // Media queries
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 850px)" });
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });

    const [openedTabs, setOpenedTabs] = useState<any>({
        color: false,
        fontSize: false,
        settings: false,
        datastream: false,
    });

    const getResultsByClass = (data: any) => {
        if (!data) return;
        if (!data.race_details?.availableClasses) return;

        const prevData = data;
        const availableClasses = prevData.race_details.availableClasses;

        prevData.race_competitors_list.sort((a: any, b: any) => {
            const classAIndex = availableClasses.indexOf(a.class);
            const classBIndex = availableClasses.indexOf(b.class);

            return classAIndex - classBIndex;
        });
        setActiveRace(prevData);
    };

    const updateLocalStorage = () => {
        setLocalStorageEnabledSettings(JSON.parse(localStorage.getItem("checkboxStates") || "{}"));
    }

    const getFilterData = (data: any) => {
        setFieldData({...fieldsData, enabled: data.fields, enabledClasses: data.classes});
    };

    function openTab(tabName: string) {
        const tmp = {...openedTabs, settings: false};
        tmp[tabName] = !openedTabs[tabName];
        setOpenedTabs(tmp);
    };

    async function dataMiddleware(data: any) {
        resultsByClass ? getResultsByClass(data) : setActiveRace(data);
        if (data.race_details?.flag === "Warmup") {
            setFieldData({...fieldsData, enabledClasses: data?.race_details?.availableClasses})
        }
        // setForceUpdate(prev => {
        //     const newValue = !prev;
        //     return newValue;
        // });
    }


    ///
    // This function is needed because of the way how ws is handling updates from orbits
    // If there are no updates - orbits is not sending out the data and as a result the percentage field is not getting
    // updated until we receive the next update from orbits
    // To combat this problem we are recalculating the percentage in frontend, which is not a good practice but the only viable workaround
    // as of now
    ///

    function manipulateData ( data: any ) {
        if (!data || !data.race_details || !data.race_competitors_list) {
            console.log("Invalid data structure");
            return data;
        }
        data?.race_competitors_list?.forEach((competitor: any) => {
            competitor.percentage = calculatePercantage(competitor, data?.race_details?.track_length,
                data?.race_time?.elapsed_time
            );
            competitor.fixed_status = competitor.status
            competitor.lap_time = competitor.lap === 0 || competitor.lap === "0" || competitor.lap === "" ? "" : competitor.lap_time
        });
        return data;
    }

    // Listener for joinRaceRoom emit
    useEffect(() => {
        props.ws.send(JSON.stringify({ type: "join_room", room_id: hash }));
        console.log('sending join_room')
        return () => {
            props.ws.send(JSON.stringify({ type: "leave_room", room_id: hash }));
        };
    }, [hash]);

    const handleActiveRaceData = async (data: any) => {
        try {
            data = manipulateData(data);
            await dataMiddleware(data);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const handleMessage = (event: any) => {
            const message = JSON.parse(event.data);
            console.log(message)
            switch (message.type) {
                case "viewer_count":
                    setCurrentViewers(message.count);
                    break;
                case "race_event":
                    handleActiveRaceData(message.data);
                    break;
                default:
                    console.log(`Unknown message type: ${message.type}`);
            }
        };

        props.ws.addEventListener('message', handleMessage);
        return () => {
            props.ws.removeEventListener('message', handleMessage);
        }
    }, [props.ws, resultsByClass]);

    return (
        <div className="app_liveList-selected-main">
            {
                openedTabs.settings &&
                <SettingsSelector callback={() => updateLocalStorage()}/>
            }
            {
                activeRace ?
                <div className="app_liveList-selected-results">
                    <ShareButtons resultsByClass={resultsByClass} />
                    {
                        openedTabs.datastream &&
                        <PacketGraph socket={props.ws} />
                    }
                    <div className="app__historyRace-table-data-selection">
                        <div
                            className={`app__historyRace-table-data-selection-tab ${
                                resultsByClass ? 'resultsByClassTrue' : 'hoverTab'
                            }`}
                            onClick={() => setResultsByClass(true)}
                            style={{color: resultsByClass ? "white" : "#9fb1cf"}}
                        >
                            Results by Class
                        </div>
                        <div
                            className={`app__historyRace-table-data-selection-tab ${
                                !resultsByClass ? 'resultsByClassFalse' : 'hoverTab'
                            }`}
                            style={{color: !resultsByClass ? "white" : "#9fb1cf"}}
                            onClick={() => setResultsByClass(false)}
                        >
                            Results by Position
                        </div>
                    </div>
                    <ActiveRaceHeader
                        activeRace={activeRace}
                        isTabletOrMobile={isTabletOrMobile}
                        currentViewers={currentViewers}
                        hash={hash}
                        openTab={(tabName: string) => openTab(tabName)}
                        setShowFilters={() => setShowFilters(!showFilters)}
                    />
                    <div className="app__live-filter">
                        <ResizableTableFilter
                            data={{
                                competitor: activeRace?.race_competitors_list,
                                race_info: activeRace?.race_details,
                                force_upd: forceUpdate
                            }}
                            fields={fieldsData}
                            callback={getFilterData}
                            show={showFilters}
                            mediaQuery={{
                                mobile: isTabletOrMobile,
                                portrait: isPortrait,
                            }}
                        />
                    </div>
                    {
                        !isTabletOrMobile && localStorageEnabledSettings?.[2] &&
                        <Simulator activeRace={activeRace} />
                    }
                    {
                        <RaceWinner
                            activeRace={activeRace}
                            isTabletOrMobile={isTabletOrMobile}
                            localStorageEnabledSettings={localStorageEnabledSettings}
                        />
                    }
                    <div className="app__historyRace-table_overflow_wrapper">
                        <div
                            className="app__historyRace-table"
                            style={{
                                width: isTabletOrMobile ? "100vw" : "auto",
                                //minWidth: isTabletOrMobile ? "100vw" : "fit-content"
                            }}
                            ref={ref}
                        >
                            <ResizableTable
                                data={{
                                    competitor: activeRace?.race_competitors_list,
                                    race_info: activeRace?.race_details
                                }}
                                fields={fieldsData}
                                fontSize={0.8}
                                colors={defaultScoreboardColors}
                                mediaQuery={{
                                    mobile: isTabletOrMobile,
                                    portrait: isPortrait,
                                }}
                                resultsByClass={resultsByClass}
                                colordersName="colorders_live"
                                colwidthsName="colwidths_live"
                            />
                        </div>
                    </div>
                    {
                        !isTabletOrMobile && localStorageEnabledSettings?.[5] &&
                        <div className="app__live_raceLeaders">
                            <div className="app__live_raceLeaders-inner">
                                <ScoreboardLeaderRow
                                    data={{
                                        competitor: activeRace?.race_competitors_list,
                                        race_info: activeRace?.race_details
                                    }}/>
                            </div>
                        </div>
                    }
                </div>
                :
                <div className="app_liveList-selected-loading"
                    style={{
                        backgroundImage: `url('/svg/Loading.svg')`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                />
            }
        </div>
    );
});
