

import './App.css';
import { RouteHistoryProvider } from './pages/mainPage/context/RouteHistoryContext';

import { MainPage } from "./pages/mainPage/mainPage";

function App() {
  return (
    <div className="App">
      <RouteHistoryProvider>
        <MainPage />
      </RouteHistoryProvider>
    </div>
  );
}

export default App;
