import { useNavigate, useParams } from "react-router-dom";
import "./raceHistoryRace.scss";
import { useEffect, useState } from "react";
import { parseDate } from "../../../../../helpers/Parsers";
import { AiOutlineFieldTime, AiOutlineClose } from "react-icons/ai";
import { BiFontSize } from "react-icons/bi";
import { IoColorPalette, IoFilterOutline } from "react-icons/io5";
import { FcRotateToLandscape, FcRotateToPortrait } from "react-icons/fc";
import { IoMdSettings } from "react-icons/io";
import "react-resizable/css/styles.css";
import Select from 'react-select';
import { useMediaQuery } from 'react-responsive'

// For ResizeTable
import ResizableTable from "../../../../../components/ResizeTable/ResizableTable";
import ResizableTableFilter from "../../../../../components/ResizeTable/ResizableFilter";
import RaceHistoryRaceWinners from "../../../../../components/RaceHistoryRaceWinners";

// ResizeTable components
import { ResizableTableStartNrComponent, ResizableTableSurnameMobileComponent } from "../../resizableTableComponents/resizableTableComponents";

import { MdArrowForwardIos, MdEmail } from "react-icons/md";
import { FaFacebookSquare } from "react-icons/fa";
import { FaTwitter, FaWhatsapp } from "react-icons/fa6";

// share
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton } from "react-share";
import { classToColor } from "../../../../../helpers/Functions";

const ResizableTableFirstnameComponent = (props: any) => {
    if (!props?.data) return null;
    const data = props?.data?.replace("/", " |");
    return (
        <span className="resized_position_firstname">
            <p>{data}</p>
        </span>
    );
};

const ResizableTableFastestLapComponent = (props: any) => {
    const isFastest = props.data === props.fastest?.best_lap_time;
    return (
        <p style={{
            color: isFastest ? "#c44df0" : "#51f6ff",
            fontWeight: isFastest ? "800" : "100",
            marginLeft: "5px"
        }}>{props.data}</p>
    );
};
const ResizableTablePosMobileComponent = (props: any) => {
    return (
        <div className="resized_position_wrapper_mobile">
            <p>{props.resultsByClass ? props.resultsByClassPosAlt : props.data}</p>
        </div>
    );
};
const ResizableTablePosComponent = (props: any) => {
    return (
        <div className="resized_position_wrapper_mobile">
            <p>{props.resultsByClass ? props.resultsByClassPosAlt : props.data}</p>
        </div>
    );
};
const ResizableTableTimeTakenComponent = (props: any) => {
    return (
        <p style={{
            color: props.data === "DNF" ? "#f03a4c" : "#3ff289",
            fontWeight: props.data === "DNF" ? "800" : "300"
        }}>{props.data}</p>
    );
};
const ResizableTableImgComponent = (props: any) => {
    if (!props.data) { return; }
        return (
            <img src={`https://flagcdn.com/${props.data?.toLowerCase()}.svg`} alt={props.name}/>
        );
};

const ResizableTableClass = (props: any) => {
    if (!props?.data) return null;

    return (
        <div className="resized_position_class">
            <div
                className="resized_position_class_color"
                style={{backgroundColor: classToColor(props.data, props.race_info?.availableClasses)}}
            />
            <p>{props.data}</p>
        </div>
    );
}

const fields = {
    banned: ["id", "race_id", "position_by_time", "position_by_lap", "diff_by_time", "diff_by_lap", "gap_by_time", "gap_by_lap", "firstname", "lastname", "position_by_class", "is_sidecar", "speed", "best_speed"],
    enabled: ["position", "nr", "state", "state2", "competitor", "class", "best_lap_time", "finished_time", "best_lap", "team", "diff", "gap", "lap", "make", "sponsor", "hometown", "tires", "model_engine"],
    verticalModeDefaults: {
        raceMode: {
            position: {
                customElement: ResizableTablePosMobileComponent,
                width: '13vw',  // percentage
                maxWidth: 60,   // pixels
                shortname: "Pos."
            },
            nr: {
                width: '12vw',
                maxWidth: 60,
                shortname: "No",
                customElement: ResizableTableStartNrComponent
            },
            competitor: {
                width: '50vw',
                justifyStart: true,
                customElement: ResizableTableSurnameMobileComponent
            },
            best_lap_time: {
                width: '25vw',
                justifyStart: true,
                shortname: "Best lap",
                customElement: ResizableTableFastestLapComponent
            },
        },
        qualificationMode: {
            position: {
                customElement: ResizableTablePosMobileComponent,
                width: '13vw',  // percentage
                maxWidth: 60,   // pixels
                shortname: "Pos."
            },
            nr: {
                width: '12vw',
                maxWidth: 60,
                shortname: "No",
                customElement: ResizableTableStartNrComponent
            },
            competitor: {
                width: '50vw',
                justifyStart: true,
                customElement: ResizableTableSurnameMobileComponent
            },
            best_lap_time: {
                width: '25vw',
                justifyStart: true,
                shortname: "Best lap",
                customElement: ResizableTableFastestLapComponent
            },
        }
    },
    enabledClasses: [],
    defaultFontSize: 20,
    order: ["position", "nr", "state", "state2", "competitor", "class", "team","sponsor", "make","model_engine","tires","hometown", "finished_time", "diff", "lap", "best_lap", "best_lap_time", "gap"],
    config: {
        position: {
            label: "Position",
            customElement: ResizableTablePosComponent,
            defaultWidth: 60
        },
        nr: {
            label: "Start No.",
            customElement: ResizableTableStartNrComponent,
            defaultWidth: 60
        },
        state: {
            label: "Nationality",
            customElement: ResizableTableImgComponent,
            isImageElement: true,
            defaultWidth: 90
        },
        state2: {
            label: "Nationality 2",
            customElement: ResizableTableImgComponent,
            defaultWidth: 90
        },
        competitor: {
            label: "Competitor",
            defaultWidth: 220,
            customElement: ResizableTableFirstnameComponent,
        },
        team: { label: "Team/Club", defaultWidth: 150 },
        best_lap_time: {
            label: "Best lap",
            defaultWidth: 150,
            customElement: ResizableTableFastestLapComponent
        },
        best_lap: {
            label: "Best in lap",
            defaultWidth: 120,
        },
        finished_time: {
            label: "Race time",
            defaultWidth: 150,
            customElement: ResizableTableTimeTakenComponent
        },
        lap: { label: "Laps" },
        diff: { label: "Difference" },
        gap: { label: "Gap" },
        class: { label: "Class", customElement: ResizableTableClass },
        make: { label: "Make"},
        model_engine: {label: "Model"},
        tires: {label: "Tires"},
        sponsor: {label: "Sponsor"},
        hometown: {label: "Hometown"}
    }
};

const fontSizeOptions: any[] = [
    {value: 0.7, label: "0.7x"},
    {value: 0.8, label: "0.8x"},
    {value: 0.9, label: "0.9x"},
    {value: 1, label: "1.0x"},
    {value: 1.1, label: "1.1x"},
    {value: 1.2, label: "1.2x"},
    {value: 1.3, label: "1.3x"},
    {value: 1.4, label: "1.4x"},
    {value: 1.5, label: "1.5x"},
];


export const RaceHistoryRace = (props: any) => {

    const {date, track_name, eventId} = useParams();
    const [raceData, setRaceData] = useState<any>(null);
    const [fieldsData, setFieldData] = useState<any>(fields);
    const [fontSize, setFontSize] = useState<number>(fontSizeOptions[0].value);
    const [selectedColors, setSelectedColors] = useState<any>(["#333645", "#232531"]);
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [showSettings, setShowSettings] = useState<boolean>(false);
    const [resultsByClass, setResultsByClass] = useState<boolean>(false);

    // Media queries
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 850px)" });
    const splitRaceWinners = useMediaQuery({ query: "(max-width: 690px)" });
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });

    const navigate = useNavigate();

    const currentRoute = [
        {name: "Home", route: "/"},
        {name: "History", route: "/history"},
        {name: date, route: `/history/${date}/${track_name}`},
        {name: "This race", route: `history/${date}/${track_name}/${eventId}`}
    ];

    const handleMessage = (event: any) => {
        const message = JSON.parse(event.data);
        switch (message.type) {
            case "event_history_race_respose": {
                message.resp?.data?.forEach((item: any) => {
                    item.parsed_date = parseDate(item?.date);
                });

                // Should be moved to backend
                const competitorsByClass: { [key: string]: any[] } = {};
                message.data?.competitors?.forEach((comp: any) => {
                    const compClass = comp.class;
                    if (!competitorsByClass[compClass]) {
                        competitorsByClass[compClass] = [];
                    }
                    competitorsByClass[compClass].push(comp);
                });
                Object.keys(competitorsByClass).forEach((compClass) => {
                    const competitors = competitorsByClass[compClass];
                    competitors.sort((a, b) => a.position - b.position);
                    competitors.forEach((comp, index) => {
                        comp.position_in_class = index + 1;
                    });
                });

                setRaceData(message.data);
                break;
            }
            default: console.log("No event provided!");
        }
    }

    useEffect(() => {
        if (!date || !track_name || !eventId ) return; // add some 404 page that says race not found or deleted
        props.ws.send(JSON.stringify({ type: "event_history_race", id: eventId }));
        props.ws.addEventListener('message', handleMessage);

        return () => {
            props.ws.removeEventListener('message', handleMessage);
        }
    }, [props.ws, date, track_name, eventId]);

    const getResultsByClass = () => {
        if (!raceData) return;
        if (!raceData.details?.availableClasses) return;

        const prevData = raceData;
        const availableClasses = prevData.details.availableClasses;


        prevData.competitors.sort((a: any, b: any) => {
            if (!resultsByClass) {
                const classAIndex = availableClasses.indexOf(a.class);
                const classBIndex = availableClasses.indexOf(b.class);

                return classAIndex - classBIndex;
            } else {
                return a.position - b.position;
            }
        });
        setResultsByClass(!resultsByClass);
        setRaceData(prevData);
    };

    const getFilterData = (data: any) => {
        setFieldData({...fieldsData, enabled: data.fields, enabledClasses: data.classes});
    };

    return (
        <div className="app__historyRace-main">
            <div className="app__historyRace-table-wrapper">
                {
                    !raceData &&
                    <div
                        className="app__historyRace-table-wrapper-load"
                        style={{
                            backgroundImage: `url('/svg/Loading.svg')`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                        }}
                    />
                }
                <div className="app__historyRace-navbar-infobox">
                    <div className="app__historyRace-table-route">
                    {
                        currentRoute.map((route: any, index: number) =>
                            <>
                                <p
                                    onClick={() => navigate(route.route)}
                                    key={"route_item_" + index}
                                >{route.name}</p>
                                {
                                    index + 1 !== currentRoute.length &&
                                    <MdArrowForwardIos />
                                }
                            </>
                        )
                    }
                    </div>
                    <div
                        className="app__historyRace-table-share"
                        style={{
                            borderBottomRightRadius: !resultsByClass ? "10px" : "0px",
                            borderBottomLeftRadius: resultsByClass ? "10px" : "0px"
                        }}
                    >
                        <EmailShareButton
                            url={window.location.href}
                        >
                            <MdEmail size={"20px"} color={"#1d9bf0"} />
                        </EmailShareButton>
                        <WhatsappShareButton
                            url={window.location.href}
                        >
                            <FaWhatsapp size={"20px"} color={"#25d366"} />
                        </WhatsappShareButton>
                        <FacebookShareButton
                            url={window.location.href}
                            hashtag={"LiveTiming"}
                        >
                            <FaFacebookSquare size={"20px"} color={"#0866ff"}/>
                        </FacebookShareButton>
                        <TwitterShareButton
                            url={window.location.href}
                            hashtags={["LiveTiming"]}
                        >
                            <FaTwitter className="share-icon-ml" size={"20px"} color={"#1d9bf0"}/>
                        </TwitterShareButton>
                    </div>
                </div>
                <div className="app__historyRace-table-data-selection">
                    <div
                        className={`app__historyRace-table-data-selection-tab ${
                            resultsByClass ? 'resultsByClassTrue' : 'hoverTab'
                        }`}
                        onClick={() => getResultsByClass()}
                    >
                        Results by Class
                    </div>
                    <div
                        className={`app__historyRace-table-data-selection-tab ${
                            !resultsByClass ? 'resultsByClassFalse' : 'hoverTab'
                        }`}
                        onClick={() => getResultsByClass()}
                    >
                        Results by Position
                    </div>
                </div>
                <div className="not-real-results">
                    <p>These are provisional results</p>
                </div>
                <div className={isTabletOrMobile ? "mobile__historyRace-data" : "app__historyRace-data"}>
                    <div className="app__historyRace-data-top">
                        <div className="app__historyRace-data-top-event-data">
                            <span>{raceData?.details?.name}</span>
                            <p>{raceData?.details?.track_name}, {parseDate(raceData?.details?.date?.split(" ")?.[0])}</p>
                        </div>
                        <div className="app__historyRace-data-top-track-data">
                            <p><AiOutlineFieldTime/>{raceData?.details?.race_time || "-"}</p>
                            <span>Track length: {raceData?.details?.track_length} km</span>
                        </div>
                    </div>
                    <div className="app__historyRace-data-bottom">
                        <RaceHistoryRaceWinners
                            data={raceData?.details}
                            mediaQuery={{
                                mobile: isTabletOrMobile,
                                portrait: isPortrait,
                                split: splitRaceWinners,
                            }}
                        />
                        <div className="app__historyRace-data-bottom-filter">
                            {
                                !isTabletOrMobile &&
                                <div className="app__historyRace-data-bottom-settings-toggle">
                                    <IoMdSettings size={"90%"} onClick={(e) => { setShowSettings(!showSettings) }}/>
                                </div>
                            }
                            {
                                !isTabletOrMobile &&
                                <div className="app__historyRace-data-bottom-filter-toggle">
                                    <IoFilterOutline size={"90%"} onClick={(e) => { setShowFilters(!showFilters) }} />
                                </div>
                            }
                            {
                                <ResizableTableFilter
                                    data={{
                                        competitor: raceData?.competitors,
                                        race_info: raceData?.details
                                        }}
                                    fields={fields}
                                    callback={getFilterData}
                                    show={showFilters}
                                    mediaQuery={{
                                        mobile: isTabletOrMobile,
                                        portrait: isPortrait,
                                    }}
                                />
                            }
                        </div>
                    </div>
                    {
                        showSettings &&
                        <div
                            className={`app__historyRace-settings ${showSettings && "historyRace-settings-50"}`}
                            style={{overflow: showSettings ? "visible" : "hidden"}}
                        >
                            <div className="app__historyRace-settings-selector">
                                <BiFontSize size={"25px"} color="azure"/>
                                <Select
                                    name="fontSize"
                                    defaultValue={fontSizeOptions[2]}
                                    className="resizable-table-filter-select"
                                    options={fontSizeOptions}
                                    onChange={(data: any) => { setFontSize(data.value) }}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#0f101a",
                                            primary: "#131417",
                                            neutral0: "#0f101a",
                                            neutral15: "#131417",
                                            neutral10: "#3d22d4",
                                            neutral20: "#282b4d",
                                            neutral80: "azure",
                                        }
                                    })}
                                />
                            </div>
                            <div className="app__historyRace-settings-selector">
                                <IoColorPalette size={"25px"} color="azure"/>
                                <input type="color" id="i65" defaultValue={selectedColors[0]} onChange={(e) => {setSelectedColors([e.target.value ,selectedColors[1]])}}/>
                                <input type="color" id="i64" defaultValue={selectedColors[1]} onChange={(e) => {setSelectedColors([selectedColors[0], e.target.value])}}/>
                            </div>
                        </div>
                    }
                </div>
                {
                    isTabletOrMobile && !isPortrait &&
                    <div className="mobile__historyRace-table-settings">
                        <div className="app__historyRace-data-bottom-filter-toggle">
                            <IoFilterOutline size={"30px"} color={"azure"} onClick={(e) => { setShowFilters(!showFilters) }} />
                        </div>
                    </div>
                }
                {
                    isTabletOrMobile ?
                    isPortrait ? <div className="mobile__historyRace-data-portait-info">
                                    <p>
                                        <FcRotateToPortrait size={"25px"}/>
                                        Rotate your device horizontaly for detailed results
                                    </p>
                                 </div>
                    : <div className="mobile__historyRace-data-non-portait-info">
                        <p>
                            <FcRotateToLandscape size={"25px"}/>
                            Rotate your device verticaly for simplified results
                        </p>
                      </div>
                    : ""
                }
                <div
                    className="app__historyRace-table"
                    style={{width: isTabletOrMobile ? "100vw" : "1600px"}}
                >
                    <ResizableTable
                        data={{
                            competitor: raceData?.competitors,
                            race_info: raceData?.details
                        }}
                        fields={fieldsData}
                        fontSize={fontSize}
                        colors={selectedColors}
                        mediaQuery={{
                            mobile: isTabletOrMobile,
                            portrait: isPortrait,
                        }}
                        resultsByClass={resultsByClass}
                        colordersName="colorders_hist"
                        colwidthsName="colwidths_hist"
                    />
                </div>
            </div>
        </div>
    );
};
