
export const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

// magic strings
export namespace raceData {
    namespace flags {
        const None = "None";
        const Green = "Green";
        const Yellow = "Yellow";
        const Red = "Red";
        const Finish = "Finish";
        const Warmup = "Warmup";
    }
    namespace raceTypes {
        const Qualification = "Qualification";
        const Race = "Race";
        const Training = "Training";
    }
}